import http from './http';
// import useFetch from '../hook/useFetch';

const to = (promise, errorExt = {}) => {
  return promise
      .then(function (data) { return [null, data]; })
      .catch(function (err) {
      if (errorExt) {
          Object.assign(err, errorExt);
      }
      return [err, undefined];
  });
}

const logError = (error) => {
  http.post('/logger', { error });
}

const getVersion = () => {
  return http.get('/wersja');
}

const getConfig = () => {
  return http.get('/konfig');
}


const postLogin = ({ username, password, rememberMe, language }) => {
  return http.post('/zaloguj', { login: username, password, rememberMe, language }, { timeout:1000 * 60 });
}

const postLoginSession = () => {
  return http.post('/zaloguj/ponownie', { }, { timeout:1000 * 60 });
}

const postLogout = () => {
  return http.post('/wyloguj');
}

const getOsoba = () => {
  return http.post('/osoba');
}

const getOsobaLog = () => {
  return http.get('/osoba/log');
}

const getAttendant = () => {
  return http.post(`/osoba/opiekun`);
};

const getUserCredit = () => {
  return http.post(`/osoba/kredyt`);
};

const getUserPoints = () => {
  return http.post(`/osoba/punkty`);
};

const getOsobaStatus = () => {
  return http.get('/osoba/status');
}

const getCatalogArticles = ({ nodekeys = [], offset = 0, perpage = 12, orderby = '', filtr = null, filtr_cechy = null, lang = 'pl', count_disable = false, count_only = false }) => {
  return (http as any).postWithCancel('/katalog', { offset, nodekeys, perpage, orderby, filtr, filtr_cechy, lang, count_disable, count_only });
}

const getCatalogCategories = ({ kat = '' }) => {
  return http.post('/katalog/kategorie', { kat });
}

const getArticles = ({ ktr, nodekeys = [], offset = 0, perpage = 20, orderby = '', filtr = null, filtr_cechy = null, available = false }) => {
  return http.post('/artykuly', { ktr, offset, nodekeys, perpage, orderby, filtr, filtr_cechy, available });
  // return (http as any).postWithCancel('/artykuly', { ktr, offset, nodekeys, perpage, orderby, filtr, filtr_cechy, available });
  // return useFetch<'articles'>('/artykuly', "post", { ktr, offset, nodekeys, perpage, orderby, filtr, filtr_cechy, available });
}

const getArticlePdf = ({ nodekeys = [], orderby = '', filtr = null, filtr_cechy = null, available = false, favorite = false, lang = 'pl' }) => {
  return http.post('/artykuly/pdf', { ktr: '', offset: 0, perpage: 1, nodekeys, orderby, filtr, filtr_cechy, available, favorite, lang });
}

const getArticlesFetch = ({ ktr, nodekeys = [], offset = 0, perpage = 20, orderby = '', filtr = null, filtr_cechy = null, available = false, favorite = false, ribbon = 0, lang = 'pl', count_disable = false, count_only = false }) => {
  return (http as any).postWithCancel('/artykuly', { ktr, offset, nodekeys, perpage, orderby, filtr, filtr_cechy, available, favorite, ribbon, lang, count_disable, count_only });
}

const getArticleBySymbol = (symbol) => {
  return http.post('/artykul/symbol', { symbol });
}

const getArticleBySymbolWithType = (symbol) => {
  return http.post('/artykul/symbol', { symbol, type_symbol: true });
}

const getCatalogArticleBySymbol = (symbol) => {
  console.log("symbol: ", symbol)
  return http.post('/katalog/artykul/symbol', { symbol });
}

const getCategories = ({ kat = '' }) => {
  return http.post('/kategorie', { kat: "" });
}

const getArticlePrice = (art = []) => {
  return http.post('/cena', { art: art });
}

const getArticleQuantity = (art = []) => {
  return http.post('/zapas', { art: art });
}

const getArticleFiles = (art, typ = '') => {
  const symbolURI = encodeURIComponent(art);
  return http.post(`/pliki/lista/${symbolURI}/${typ}`, null);
}

const getArticleFilesCount = (symbol, typ = 'Z') => {
  const symbolURI = encodeURIComponent(symbol);
  return http.post(`/pliki/count/${symbolURI}/${typ}`, null);
}

const getArticleFilesPack = (symbol) => {
  const symbolURI = encodeURIComponent(symbol);
  return http.post(`/pliki/paczka/${symbolURI}`, null, { responseType: 'blob' });
}

const getArticleCrossSellName = (art, visible_cart, visible_art) => {
  return http.post('/artykuly/crosssell/name', { art, visible_cart, visible_art });
}

const getArticleCrossSellList = (art, id_cross_selling, visible_cart, visible_art) => {
  return http.post('/artykuly/crosssell/list', { art, id_cross_selling, visible_cart, visible_art });
}

const addArticleFavorite = (symbol) => {
  return http.post('/artykuly/favorite/add', { symbol });
}

const delArticleFavorite = (id, all = false) => {
  return http.post('/artykuly/favorite/del', { id, all });
}

const cntArticleFavorite = () => {
  return http.post('/artykuly/favorite/cnt');
}

const getArticleRibbonName = () => {
  return http.post('/artykuly/ribbon/name');
}

const getCatalogArticleCrossSelling = (art) => {
  return http.post('/katalog/artykuly/crossselling', {art});
}

const getArticleVariants = (art) => {
  return http.post('/artykuly/variants', {art});
}

const getCatalogArticleVariantsBySymbol = (art) => {
  return http.post('/katalog/artykuly/variants', {art});
}

const getArticleProperties = (artList) => { // array
  return http.post('/artykuly/properties', { symbols: artList.map(el => ({ art: el })) });
}

const getDocumentFile = (idView, keyDoc, docWhere, posWhere) => {
  return http.post(`/pliki/raport`, { view: idView, key: ""+keyDoc, docWhere, posWhere });
}

const getAreaMenu = () => {
  return http.post('/strefa/menu');
}

const getAreaDefinition = (id: number, type: string, where: string | null = null) => {
  return (http as any).postWithCancel('/strefa/definicje', { id, type, where });
}

const getAreaValues = (id: number, type: string, where: string | null = null, offset: number | null = null, perpage: number | null = null, search: string = "") => {
  return (http as any).postWithCancel('/strefa/wartosci', { id, type, where, offset, perpage, columnfilter: search }, { timeout: 1000 * 122 });
}

const getMenuModules = () => {
  return http.post('/menu');
}

const getFileExcel = (title: string, header: string, data: string) => {
  return http.post(`/strefa/excel`, { title, header, data }, { responseType: 'blob' });
}


// user api
const getUserPassword = (user) => {
  return http.post(`/klient/haslo`, { hash: user.hash });
};

const setUserPasswordByHash = (hash, password) => {
  return http.post(`/klient/haslo/hash`, { hash, password });
};

const setUserPasswordByPass = (pass_old, pass_new ) => {
  return http.post(`/klient/haslo/pass`, { pass_old, pass_new });
};

const getSliderCount = () => {
  return http.post(`/slider/count`);
};

const resetUserPassword = (login) => {
  return http.post(`/klient/haslo/reset`, { login });
};

const getUserByHash = (hash) => {
  return http.post(`/klient/hash`, { hash });
};

const getTraderContractor = () => {
  return http.get(`/handlowiec/kontrahent`);
}

const postTraderContractor = (ktr: string) => {
  return http.post(`/handlowiec/kontrahent`, { ktr });
}

const getTraderDiscount = (symbol: string, jm: string, convert: number) => {
  return http.post(`/handlowiec/koszyk/rabat`, { symbol, jm, convert });
}

const getTraderBranch = () => {
  return http.get(`/handlowiec/oddzial`);
}

const postTraderBranch = (odb: string) => {
  return http.post(`/handlowiec/oddzial`, { odb });
}

const getBranchPrice = (symbol: string) => {
  return http.post(`/handlowiec/oddzial/artykul`, { symbol });
}

const setBranchPrice = (symbol: string, name: string, price: number, date_from: Date, date_to: Date) => {
  return http.post(`/handlowiec/oddzial/cena`, { symbol, name, price, date_from, date_to });
}



// cart api
const getCart = (nag_id = 0, typ = 'Z') => http.post('/koszyk', { nag_id, typ });

const getCartElement = (nag_id = 0, poz_id = 0) => http.post('/koszyk/pozycje', { nag_id, poz_id });

const setCart = (name = '', id = 0, typ = 'Z', extension = {}, thirdside = {}) => {
  return http.post('/koszyk/zapisz', { name, nag_id: id, typ, extension, thirdside });
}

const delCart = (id = 0, typ = 'Z') => http.post('/koszyk/usun', { nag_id: id, typ });

const setCartElement = (product) => http.post('/koszyk/zapisz/pozycje', product);

const delCartElement = (product) => http.post('/koszyk/usun/pozycje', product);

const getCartForm = () => http.post('/koszyk/formy');

const sendOrder = (nag_id) => http.post('/koszyk/zamowienie', { nag_id }, { timeout: 1000 * 60 * 10 });

const statusOrder = (nag_id) => http.post('/koszyk/zamowienie/status', { nag_id });

const unlockOrder = (key) => http.post('/koszyk/zamowienie/odblokuj', { key });

const deleteOrder = (key) => http.post('/koszyk/zamowienie/usun', { key });

const getFilter = (node_key) => http.post('/filtry', { node_key });

const getCartPdf = (nag_id, name) => http.post('/koszyk/pdf', { nag_id, name });

const getCartCsvExample = () => http.post('/koszyk/csv/example');

// file cart

const uploadFileCart = (formData) => {
  return http.post('/koszyk/attachment/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

const deleteFileCart = (id) => http.post('/koszyk/attachment/delete', {id});

const listFileCart = (id) => http.post('/koszyk/attachment/list', {id});

// porta api

const getColors = () => http.post('/colors');

const getCityName = (postalcode) => http.post('/cityname', { postalcode });

const getFilterCatalog = (node_key) => http.post('/katalog/filtry', { node_key });

const postLang = (lng) => http.post("/lang", { lng });

const getLangs = () => http.get("/lang");

// otp
const checkOtpStatus = () => http.get('/otp/status');

const setOtpDisable = () => http.post('/otp/disable');

const getOtpSetup = () => http.post('/otp/setup');

const validateOtpAuth = (code, secret = '') => http.post('/otp/auth', { code, secret });

// -- paths
const pathArticles = () => `${http.defaults.baseURL}/artykuly`;

const pathArticleImage = (symbol, width = 40) => {
  const symbolURI = encodeURIComponent(symbol);
  return `${http.defaults.baseURL}/pliki/${width}/${symbolURI}`;
};

// image cache
interface FileCache {
  getArticleSticker(symbol: Required<string>, num: number, width: number): Promise<any>
  getArticleImage(symbol: Required<string>, num: number, width: number): Promise<any>
  getArticleDocument(symbol: Required<string>, num: number): Promise<any>;
  getSliderImage(num: number, id: string, width: number): Promise<any>;
  getLogoImage(): Promise<any>;
}

const fileCache : FileCache = {
  getArticleSticker: (symbol, num = 0, width = 60) => {
    const symbolURI = encodeURIComponent(symbol);
    return http.post(`/pliki/${num}/${symbolURI}/E/${width}`, null, { responseType: 'blob' });
  },
  getArticleImage: (symbol, num = 0, width = 40) => {
    const symbolURI = encodeURIComponent(symbol);
    return http.post(`/pliki/${num}/${symbolURI}/Z/${width}`, null, { responseType: 'blob' });
  },
  getArticleDocument: (symbol, num = 0, _ = 0) => {
    const symbolURI = encodeURIComponent(symbol);
    return http.post(`/pliki/${num}/${symbolURI}/P`, null, { responseType: 'blob' });
  },
  getSliderImage: (num = 0, id = "", width = 1024) => {
    return http.post(`/slider/${num}/${id}`, null, { responseType: 'blob' });
  },
  getLogoImage: () => {
    return http.post(`/logo`, null, { responseType: 'blob' });
  }
};

export default {
  to,
  logError,
  getConfig,
  getVersion,
  postLogin,
  postLoginSession,
  postLogout,
  getOsoba,
  getOsobaLog,
  getOsobaStatus,
  getCatalogArticles,
  getCatalogCategories,
  getCatalogArticleBySymbol,
  getCatalogArticleVariantsBySymbol,
  getCatalogArticleCrossSelling,
  getFilterCatalog,
  getArticles,
  getArticlesFetch,
  getArticleBySymbol,
  getArticleBySymbolWithType,
  getCategories,
  getArticlePdf,
  getArticlePrice,
  getArticleQuantity,
  getArticleFiles,
  getArticleFilesCount,
  getArticleFilesPack,
  pathArticles,
  pathArticleImage,
  getCart,
  getCartElement,
  setCart,
  delCart,
  setCartElement,
  delCartElement,
  sendOrder,
  statusOrder,
  unlockOrder,
  deleteOrder,
  getUserPassword,
  setUserPasswordByHash,
  setUserPasswordByPass,
  fileCache,
  getCartForm,
  getFilter,
  getArticleCrossSellName,
  getArticleCrossSellList,
  getArticleVariants,
  getArticleProperties,
  addArticleFavorite,
  delArticleFavorite,
  cntArticleFavorite,
  getArticleRibbonName,
  getDocumentFile,
  getAreaMenu,
  getAreaDefinition,
  getAreaValues,
  getMenuModules,
  getSliderCount,
  getAttendant,
  getUserCredit,
  getUserPoints,
  resetUserPassword,
  getUserByHash,
  getTraderContractor,
  postTraderContractor,
  getTraderBranch,
  postTraderBranch,
  getTraderDiscount,
  getBranchPrice,
  setBranchPrice,
  getFileExcel,
  getColors,
  getCityName,
  uploadFileCart,
  deleteFileCart,
  listFileCart,
  getCartPdf,
  postLang,
  getLangs,
  checkOtpStatus,
  setOtpDisable,
  getOtpSetup,
  validateOtpAuth,
  getCartCsvExample,
};
